import React from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api'
import WorkTime from './WorkTime'

const SelectedLocationInfo = ({
  title,
  address1,
  address2,
  googleLink,
  coords,
  onClose,
  workingHours,
}) => {
  const town = address2.split(', ')[1]
  const infoWindowTitle = 'text-lg mb-2'
  return (
    <InfoWindow position={coords} onCloseClick={onClose}>
      <div>
        <h1 className={infoWindowTitle}>{title}</h1>
        <WorkTime {...workingHours} />
        <br />
        <p>
          {address1}
          {town ? `, ${town}` : null}
        </p>
        {
          <p>
            <a
              className={'text-blue-600'}
              href={googleLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Prikaži na Google kartama
            </a>
          </p>
        }
      </div>
    </InfoWindow>
  )
}

const mapsLibs = ['places']

function MyComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: props.apiKey,
    libraries: mapsLibs,
  })

  const [map, setMap] = React.useState(null)
  const locationMarkers = props.locations.map((location) => {
    return (
      <Marker
        position={location.coords}
        key={location.placeId}
        onClick={() => props.setActiveLocation(location.placeId)}
      />
    )
  })

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  React.useEffect(() => {
    if (map && props.selectedLocation) {
      map.panTo(props.selectedLocation.coords)
    }
  }, [map, props.selectedLocation])

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName={'w-full max-w-3xl h-125 rounded-l-md'}
      center={props.mapOptions.center}
      zoom={11}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {locationMarkers}
      {props.selectedLocation && (
        <SelectedLocationInfo
          {...props.selectedLocation}
          onClose={props.onCloseInfoWindow}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(MyComponent)
