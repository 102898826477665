import React from 'react'

// import Swiper core and required modules
import { Pagination, Autoplay } from 'swiper/modules'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

export default function Carousel({ images, classNames }) {
  let fastLoadFirst = 3
  let slides = images.map((imagePath) => {
    let loading = fastLoadFirst <= 0 ? 'lazy' : 'eager'
    fastLoadFirst -= 1
    return (
      <SwiperSlide key={imagePath} style={{ width: 'auto', minWidth: '300px' }}>
        <img
          src={imagePath}
          className="w-auto h-full shadow-lg rounded-xl"
          loading={loading}
        />
      </SwiperSlide>
    )
  })

  return (
    <Swiper
      loop={true}
      modules={[Autoplay]}
      lazy={{
        enabled: true,
        loadPrevNext: false,
        loadOnTransitionStart: true,
      }}
      slidesPerView={'auto'}
      spaceBetween={25}
      autoplay={{
        delay: 3000,
        disableOnInteraction: true,
      }}
      speed={1000}
      pagination={{
        clickable: true,
      }}
      className={classNames}
    >
      {slides}
    </Swiper>
  )
}
