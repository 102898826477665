import React from 'react'

import LightGallery from 'lightgallery/react'

// import styles
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss'
import 'lightgallery/scss/lg-zoom.scss'

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import fjGallery from 'flickr-justified-gallery'

import { useEffect } from 'react'

export default function Gallery({ imageSources }) {
  useEffect(() => {
    fjGallery(document.querySelectorAll('.gallery'), {
      itemSelector: '.gallery-item',
      rowHeight: 300,
      lastRow: 'hide',
      gutter: 10,
      rowHeightTolerance: 0.25,
      calculateItemsHeight: false,
    })
  }, [])

  let images = imageSources.map((imageSource, index) => {
    return (
      <div key={index} className="gallery-item" data-src={imageSource.url}>
        <img
          className="img-responsive rounded-lg"
          src={imageSource.thumbnail_url}
        />
      </div>
    )
  })

  return (
    <LightGallery
      elementClassNames="gallery w-full"
      thumbnail={true}
      pager={false}
      plugins={[lgThumbnail, lgZoom]}
    >
      {images}
    </LightGallery>
  )
}
