import React from 'react'

import WorkTime from './WorkTime'

const LocationButton = ({
  selected,
  onClick,
  address1,
  address2,
  title,
  placeId,
  workingHours,
}) => {
  const selectedStyle = ' border-4 border-blue-500'
  let btnStyle = 'box-border py-3 shadow-sm rounded-md'
  if (selected) {
    btnStyle += selectedStyle
  }

  return (
    <li className={btnStyle}>
      <button className={'w-full h-full'} onClick={() => onClick(placeId)}>
        <div className={'text-bold text-lg'}>{title}</div>
        {address1}
        <br />
        {address2}
        {selected && <WorkTime {...workingHours} />}
      </button>
    </li>
  )
}

const LocationList = (props) => {
  const locationItems = props.locations.map((location) => {
    return (
      <LocationButton
        key={location.placeId}
        onClick={props.selectLocation}
        selected={location.placeId === props.selectedId}
        {...location}
      />
    )
  })
  return (
    <div
      className={
        'flex flex-col flex-grow rounded-lg lg:rounded-l-none lg:h-125 max-w-md bg-white'
      }
    >
      <div
        className={
          'mx-auto font-bold text-lg border-b-2  border-black px-32 py-2'
        }
      >
        {' '}
        Lokacije
      </div>
      <ul className={'w-full h-full overflow-auto flex flex-col items-stretch'}>
        {locationItems}
      </ul>
    </div>
  )
}
export default LocationList
