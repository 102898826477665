// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
// import * as ActiveStorage from '@rails/activestorage'
// import 'channels'
// Load scss (tailwind etc)
import 'stylesheets/application.scss'

// Load React
import './react'

// document.addEventListener("turbolinks:load", () => {
//   $('[data-toggle="tooltip"]').tooltip()
//   $('[data-toggle="popover"]').popover()
// })

// If shakapacker was to be used for shop, instead of importmaps
// import "../controllers"
// import "@hotwired/turbo-rails"

Rails.start()
// ActiveStorage.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
