import React, { useState } from 'react'
import Map from './Map'
import LocationList from './LocationList'

const CONFIGURATION = {
  locations: [
    {
      title: 'Vrtni centar Podsused',
      address1: 'Podsusedska aleja 12',
      address2: '10000, Zagreb, Hrvatska',
      coords: { lat: 45.81645688383391, lng: 15.8370991 },
      placeId: 'ChIJw2GlDd3RZUcRu6bg_InMrjg',
      googleLink: 'https://goo.gl/maps/4cfd6refrFtkTw3d8',
      workingHours: {
        weekdays: '8:00-17:00',
        sat: '7:30-14:00',
        //"sun": "ne radimo"
      },
    },
    {
      title: 'Poslovnica Zaprešić',
      address1: 'Tržna ul. 1',
      address2: '10290, Zaprešić, Hrvatska',
      coords: { lat: 45.855959776000816, lng: 15.803622969311508 },
      placeId: 'ChIJEUCSyqPPZUcRPibwuoxFNwA',
      googleLink: 'https://goo.gl/maps/JKcDZweqwfjxDYPY8',
      workingHours: {
        weekdays: '8:00-17:00',
        sat: '8:00-13:00',
        //"sun": "ne radimo"
      },
    },
  ],
  mapOptions: {
    center: { lat: 45.817338, lng: 15.8415 },
    fullscreenControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    zoom: 4,
    zoomControl: true,
    maxZoom: 17,
  },
  mapsApiKey: 'AIzaSyClJcalSDqJsr3Q2CKJuM0GmyeBUKuzK-c',
}

export default function (props) {
  const [activeLocationId, setActiveLocation] = useState('')
  const selectedLocation = CONFIGURATION.locations.find(
    (loc) => loc.placeId === activeLocationId
  )

  const handleSelectLocation = (id) => {
    setActiveLocation(id)
  }

  const handleCloseInfoWindow = (id) => {
    setActiveLocation('')
  }

  return (
    <div className={'flex flex-wrap justify-center'}>
      <Map
        locations={CONFIGURATION.locations}
        mapOptions={CONFIGURATION.mapOptions}
        selectedLocation={selectedLocation}
        onCloseInfoWindow={handleCloseInfoWindow}
        apiKey={props.apiKey}
        setActiveLocation={handleSelectLocation}
      />
      <LocationList
        locations={CONFIGURATION.locations}
        active={activeLocationId}
        selectLocation={handleSelectLocation}
        selectedId={activeLocationId}
      />
    </div>
  )
}
