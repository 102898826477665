import ReactOnRails from 'react-on-rails'

import Navbar from '../components/navbar/Navbar'
import Carousel from '../components/Carousel'
import Locations from '../components/location/Locations'
import ContactFormToggle from '../components/disclosure/ContactFormToggle'
import Gallery from '../components/Gallery'

ReactOnRails.register({
  Navbar,
  Carousel,
  Locations,
  ContactFormToggle,
  Gallery,
})
